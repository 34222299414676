import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GateAdminList, UserRole } from "smartbox-types";
import { gateList } from "smartbox-tables";
import { showModal } from "../../../../../../../actions/modal";
import { successNotification } from "../../../../../../../actions/notifications";
import ApiService from "../../../../../../../services/api-service";
import { ApiTable, ConfirmModal } from "../../../../../../../components/Common";
import { Button } from "../../../../../../../components/Branded";
import {
  Section,
  ButtonsContainer,
  SimpleHeader,
} from "../../../../../../../components/Layout";
import { __ } from "../../../../../../../helpers/i18n";
import { InteractiveTableSchema } from "../../../../../../../utils/table-schema-utils";
import { refreshApiTableData } from "../../../../../../../actions/api-table";
import { useParams } from "react-router";
import UserService from "../../../../../../../services/user-service";
import { ApplicationState } from "../../../../../../../reducers";
import { gate } from "../../../../../../../actions";

const List = () => {
  const user = useSelector((state: ApplicationState) => state.user.details!);
  const dispatch = useDispatch();
  const scheme = new InteractiveTableSchema(gateList);
  const { stockId } = useParams<{ stockId: string }>();

  const hasFullAccess = [UserRole.admin, UserRole.client].includes(user.role);

  scheme.addFieldAtTheEnd({
    name: __("application.actions"),
    field: "actions",
    mobile: true,
    buttons: [
      {
        name: "application.openGate",
        type: "secondary",
        click: (data: GateAdminList) => openGate(data),
      },
      hasFullAccess
        ? {
            name: "application.edit",
            type: "primary",
            to: (data: GateAdminList) =>
              `/${UserService.getSlugByRole(user.role)}/locations/stocks/${stockId}/gates/${data.id}/edit`,
          }
        : null,
      hasFullAccess
        ? {
            name: "application.delete",
            type: "danger",
            click: (data: GateAdminList) => confirmGateDelete(data),
          }
        : null,
    ],
  });

  const openGate = (data: GateAdminList) => {
    dispatch(gate.askGateToOpenByStaff(data.id));
  };

  const confirmGateDelete = (data: GateAdminList) => {
    dispatch(
      showModal(
        <ConfirmModal
          mainText="application.confirmGateDelete"
          approveButtonText="application.yes"
          approveAction={() => deleteGate(data)}
        />
      )
    );
  };

  const deleteGate = async (data: GateAdminList) => {
    await ApiService.callFetch("DELETE", `gate/${data.id}`, () => {
      dispatch(successNotification("application.gateDeleted"));
      dispatch(refreshApiTableData());
    });
  };

  return (
    <Section full>
      <SimpleHeader title="application.gatesList" />
      {hasFullAccess && (
        <ButtonsContainer>
          <Button
            text="application.addGate"
            primary
            to={`/${UserService.getSlugByRole(user.role)}/locations/stocks/${stockId}/gates/add`}
          />
        </ButtonsContainer>
      )}
      <ApiTable
        scheme={scheme}
        apiEndpointSubUrl={`gate/list/stock/${stockId}`}
      />
    </Section>
  );
};

export { List };
