import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { IStock, TenantEntity, UserRole } from "smartbox-types";

import {
  PageHeader,
  ButtonsContainer,
  Section,
} from "../../../../../components/Layout";
import { Button, Tabs } from "../../../../../components/Branded";
import { Redirect, Route, Switch, useParams } from "react-router";
import UserService from "../../../../../services/user-service";
import { ApplicationState } from "../../../../../reducers";
import { BasicData } from "../../../StockGroups/Stocks/Manage/BasicData";
import { Boxes } from "./Boxes";
import { Rents } from "./Rents";
import { Map } from "./Map";
import { Groups } from "./Groups";
import { Discounts } from "./Discounts";
import ApiService from "../../../../../services/api-service";
import { Handling } from "./Handling";
import { Gates } from "./Gates";
import { Addons } from "./Addons";

const Manage = () => {
  const { stockId } = useParams<{ stockId: string }>();
  const user = useSelector((state: ApplicationState) => state.user.details);
  const tenant = useSelector((state: ApplicationState) => state.tenant);
  const [tenantForAdmin, setTenantForAdmin] = useState<TenantEntity>();
  const [isMapActive, setMapActive] = useState<boolean>(false);

  const hasFullAccess = [UserRole.admin, UserRole.client].includes(user!.role);

  const getStockData = async () => {
    await ApiService.callFetch("GET", `stock/${stockId}`, (stock: IStock) => {
      setMapActive(stock.isMapActive);
      if (user?.role === UserRole.admin) getTenantForAdmin(stock.tenantId);
    });
  };

  const getTenantForAdmin = async (tenantId: string) => {
    await ApiService.callFetch(
      "GET",
      `tenant/admin/${tenantId}`,
      (tenant: TenantEntity) => {
        setTenantForAdmin(tenant);
      }
    );
  };

  useEffect(() => {
    getStockData();
  }, []);

  const tabs = useMemo(() => {
    const elements = hasFullAccess
      ? [
          {
            to: `/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/data`,
            text: "application.stockBasicData",
          },
          {
            to: `/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/rents`,
            text: "application.stockRents",
          },
          {
            to: `/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/gates`,
            text: "application.gates",
          },
          {
            to: `/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/groups`,
            text: "application.boxesGroups",
          },
          {
            to: `/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/discounts`,
            text: "application.stockSale",
          },
          {
            to: `/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/boxes`,
            text: "application.stockBoxes",
          },
        ]
      : [
          {
            to: `/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/rents`,
            text: "application.stockRents",
          },
          {
            to: `/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/gates`,
            text: "application.gates",
          },
          {
            to: `/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/boxes`,
            text: "application.stockBoxes",
          },
        ];

    if (isMapActive) {
      if (hasFullAccess) {
        elements.push({
          to: `/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/map`,
          text: "application.map",
        });
      }
      elements.push({
        to: `/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/handling`,
        text: "application.stockHandling",
      });
    }

    if (hasFullAccess && (tenantForAdmin?.allowAddonsToRent || tenant?.allowAddonsToRent)) {
      elements.push({
        to: `/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/addons`,
        text: "application.stockAddons",
      });
    }

    return elements;
  }, [isMapActive, tenantForAdmin]);

  return (
    <Section>
      <PageHeader title="application.stockManage" />
      <ButtonsContainer>
        <Button to="/admin/locations/stocks" text="application.back" primary />
      </ButtonsContainer>

      <Tabs tabs={tabs} />

      <Switch>
        <Route
          exact
          path={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/:stockId`}
          render={() =>
            hasFullAccess ? (
              <Redirect
                to={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/data`}
              />
            ) : (
              <Redirect
                to={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/${stockId}/rents`}
              />
            )
          }
        />
        {hasFullAccess && (
          <Route
            exact
            path={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/:stockId/data`}
            component={BasicData}
          />
        )}
        <Route
          path={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/:stockId/gates`}
          component={Gates}
        />
        {hasFullAccess && (
          <Route
            path={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/:stockId/settings`}
            component={BasicData}
          />
        )}

        <Route
          path={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/:stockId/boxes`}
          component={Boxes}
        />
        <Route
          path={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/:stockId/rents`}
          component={Rents}
        />
        {hasFullAccess && (
          <Route
            path={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/:stockId/groups`}
            component={Groups}
          />
        )}
        {hasFullAccess && (
          <Route
            path={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/:stockId/discounts`}
            component={Discounts}
          />
        )}
        {hasFullAccess && (
          <Route
            path={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/:stockId/addons`}
            component={Addons}
          />
        )}
        {isMapActive && hasFullAccess && (
          <Route
            path={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/:stockId/map`}
            component={Map}
          />
        )}
        {isMapActive && (
          <Route
            path={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/:stockId/handling`}
            component={Handling}
          />
        )}
        {(tenantForAdmin?.allowAddonsToRent || tenant?.allowAddonsToRent) &&
          hasFullAccess && (
            <Route
              path={`/${UserService.getSlugByRole(user!.role)}/locations/stocks/:stockId/addons`}
              component={Addons}
            />
          )}
      </Switch>
    </Section>
  );
};

export { Manage };
